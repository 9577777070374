<template>
    <div :class="{ flex: dashboardDisabledForGroups }">
        <warning
            :icon="$icons.warning"
            :title="$t('error.warning')"
            :content="$t('dashboards.disabledForGroups')"
            :show-home="true"
            :show-back="true"
            v-if="dashboardDisabledForGroups"
        />
        <template v-else>
            <portal to="content-header-left">
                <activix-reload :loading="isLoading" @click="fetchUsers" />
            </portal>

            <portal to="content-header-right">
                <activix-multiselect
                    name="month"
                    label="text"
                    min-width="215"
                    :white="true"
                    :selected="!empty(selectedMonth) ? selectedMonth : initialMonth"
                    :options="actualMonths"
                    :searchable="false"
                    :allow-empty="false"
                    v-model="selectedMonth"
                />
            </portal>

            <div class="box" :class="{ loading: isLoading }">
                <div class="box-body" :class="{ 'p-0': lgLayout }">
                    <div class="overflow-x-auto">
                        <table class="table table-bordered table-striped | border">
                            <thead>
                                <tr>
                                    <th class="">
                                        {{ $t('activityReport.objectives.agent') }}
                                    </th>
                                    <th class="text-center" colspan="4">
                                        {{ $t('activityReport.advancedStats.boxTitles.phoneUp') }}
                                    </th>
                                    <th class="text-center" colspan="3">
                                        {{ $t('activityReport.advancedStats.boxTitles.walkIn') }}
                                    </th>
                                    <th class="text-center" colspan="4">
                                        {{ $t('activityReport.advancedStats.boxTitles.web') }}
                                    </th>
                                    <th class="text-center" colspan="4">
                                        {{ $t('activityReport.advancedStats.boxTitles.renewal') }}
                                    </th>
                                </tr>
                                <tr>
                                    <th />
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingCalls', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingEmails', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.appointments', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.sales', 2) }}
                                    </th>

                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingCalls', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingEmails', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.sales', 2) }}
                                    </th>

                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingCalls', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingEmails', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.appointments', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.sales', 2) }}
                                    </th>

                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingCalls', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.outgoingEmails', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.appointments', 2) }}
                                    </th>
                                    <th class="text-center">
                                        {{ $tc('activityReport.stats.sales', 2) }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="empty(objectives)">
                                    <td class="text-center" colspan="100">
                                        {{ $t('objectives.noObjectives') }}
                                    </td>
                                </tr>
                                <tr :key="index" v-for="(objective, index) in objectives">
                                    <td class="whitespace-nowrap">
                                        {{ objective.user.fullName }}
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="phoneup-outgoing-calls"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.phoneUp.outgoingCalls"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'phoneUp.outgoingCalls', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="phoneup-outgoing-emails"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.phoneUp.outgoingEmails"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'phoneUp.outgoingEmails', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="phoneup-appointments"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.phoneUp.appointments"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'phoneUp.appointments', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="phoneup-outgoing-sales"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.phoneUp.sales"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'phoneUp.sales', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="walkin-outgoing-calls"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.walkIn.outgoingCalls"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'walkIn.outgoingCalls', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="walkin-outgoing-emails"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.walkIn.outgoingEmails"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'walkIn.outgoingEmails', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="walkin-sales"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.walkIn.sales"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'walkIn.sales', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="web-outgoing-calls"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.web.outgoingCalls"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'web.outgoingCalls', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="web-outgoing-emails"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.web.outgoingEmails"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'web.outgoingEmails', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="web-appointments"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.web.appointments"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'web.appointments', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="web-sales"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.web.sales"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'web.sales', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="renewal-outgoing-calls"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.renewal.outgoingCalls"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'renewal.outgoingCalls', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="renewal-outgoing-emails"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.renewal.outgoingEmails"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'renewal.outgoingEmails', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="renewal-appointments"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.renewal.appointments"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'renewal.appointments', $event)"
                                        />
                                    </td>
                                    <td class="text-center">
                                        <text-input
                                            name="renewal-appointments"
                                            type="numeric"
                                            input-class="w-12"
                                            :value="objective.renewal.sales"
                                            ref="statsInputs"
                                            @accepted="onAccept(index, 'renewal.sales', $event)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    // Utils
    import { assign, cloneDeep, debounce, each, get, merge, set } from 'lodash-es';
    import { mapState } from 'pinia';
    import { merge as deepMerge } from '../../utils/index.js';

    // Entities
    import User from '../../entities/User.js';
    import DashboardType from '../../entities/DashboardType.js';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';

    // Components
    import TextInput from '../../components/inputs/TextInput.vue';
    import Warning from '../../components/Warning.vue';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            TextInput,
            Warning,
        },

        mixins: [TrackView],

        data() {
            return {
                users: [],
                disableUpdates: false,
                selectedUser: {},
                objectives: {},
                currentObjective: {},
                usersToUpdate: [],
                currentMonth: now().month(),
                nextYear: now().year() + 1,
                selectedMonth: {},
                months: [
                    'january',
                    'february',
                    'march',
                    'april',
                    'mai',
                    'june',
                    'july',
                    'august',
                    'september',
                    'october',
                    'november',
                    'december',
                ],
                defaultObjective: {
                    id: null,
                    phoneUp: {
                        outgoingCalls: 0,
                        outgoingEmails: 0,
                        appointments: 0,
                        sales: 0,
                    },
                    walkIn: {
                        outgoingCalls: 0,
                        outgoingEmails: 0,
                        appointments: 0,
                        sales: 0,
                    },
                    web: {
                        outgoingCalls: 0,
                        outgoingEmails: 0,
                        appointments: 0,
                        sales: 0,
                    },

                    renewal: {
                        outgoingCalls: 0,
                        outgoingEmails: 0,
                        appointments: 0,
                        sales: 0,
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextGroup: 'group',
                contextUser: 'user',
            }),
            ...mapState(useDashboardStore, ['dashboardDisabledForGroups']),

            validAccount() {
                return get(this.contextAccount, 'id');
            },

            isLoading() {
                return this.$wait.is(['fetching.contextAccount', 'fetching.objectives']);
            },

            actualMonths() {
                const months = [];

                this.months.forEach((month, index) => {
                    months.push({
                        id: index,
                        text: `${this.$t(`saleTable.months.${month}`)} - ${
                            this.currentMonth == 11 && index == 0 ? this.nextYear : this.nextYear - 1
                        }`,
                    });
                });

                return months;
            },

            initialMonth() {
                if (!empty(this.actualMonths)) {
                    return this.actualMonths.find(item => {
                        return item.id === now().month();
                    });
                }

                return [];
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.fetchUsers();
                },
            },

            selectedMonth() {
                this.cancelAll();
                this.setObjectives();
            },
        },

        methods: {
            setObjectives() {
                if (this.dashboardDisabledForGroups) {
                    return;
                }

                this.disableUpdates = true;

                const objectives = {};
                const month = !empty(this.selectedMonth) ? this.selectedMonth : this.initialMonth;

                // Set objectives
                this.users.forEach(user => {
                    let objective = cloneDeep(this.defaultObjective);

                    objective.user = user;

                    each(user.objectives, userObjective => {
                        const definedDate = as_locale(userObjective.defined_date, 'defined_date');

                        if (
                            userObjective.type === 'activityReport' &&
                            (definedDate.month() == month.id ||
                                (month.id == 13 && definedDate.month() == 1 && definedDate.year() == now().year() + 1))
                        ) {
                            const values = assign(userObjective.values, { id: userObjective.id });
                            objective = merge(objective, values);
                        }
                    });

                    objectives[user.id] = objective;
                });

                if (!empty(objectives)) {
                    this.objectives = objectives;
                }

                this.$nextTick(() => {
                    this.disableUpdates = false;
                });
            },

            onAccept(id, prop, value) {
                if (this.usersToUpdate.indexOf(id) !== -1 || this.disableUpdates) {
                    return;
                }

                const parsedValue = parseInt(typeof value === 'string' ? value.replace(/\s+/g, '') : value, 10);

                if (Number.isNaN(parsedValue)) {
                    return;
                }

                set(this.objectives, `[${id}]${prop}`, value);

                this.usersToUpdate.push(id);
                this.updateObjective();
            },

            updateObjective() {
                const userIds = this.usersToUpdate;
                const month = !empty(this.selectedMonth) ? this.selectedMonth : this.initialMonth;
                const monthId = parseInt(month.id, 10);
                const date =
                    monthId == 12
                        ? now()
                            .month(0)
                            .addYears(1)
                        : now().month(monthId);

                // Reset users array
                this.usersToUpdate = [];

                userIds.forEach(userId => {
                    const payload = {
                        objective: {
                            id: this.objectives[userId].id,
                            type: 'activityReport',
                            defined_date: date.startOfMonth().toString(),
                            values: {
                                phoneUp: this.objectives[userId].phoneUp,
                                walkIn: this.objectives[userId].walkIn,
                                web: this.objectives[userId].web,
                                renewal: this.objectives[userId].renewal,
                            },
                        },
                    };

                    this.updateUser(userId, payload);
                });
            },

            cancelAll() {
                if (this.$refs.statsInputs) {
                    this.$refs.statsInputs.forEach(input => {
                        input.cancel();
                    });
                }
            },

            async fetchUsers() {
                if (!this.validAccount) {
                    return;
                }

                this.$wait.start('fetching.objectives');

                try {
                    const users = await this.$api.users.index({
                        filter: {
                            id: this.contextUser.id,
                            accountId: this.contextAccount.id,
                        },
                        include: ['objectives'],
                    });

                    this.users = users.filter(user => !user.hide_in_user_select).map(user => new User(user));

                    this.setObjectives();

                    this.$wait.end('fetching.objectives');
                } catch (error) {
                    this.$wait.end('fetching.objectives');
                    this.$notify.error(this.$t('objectives.alerts.index.error'));

                    throw error;
                }
            },

            async updateUser(userId, payload) {
                try {
                    const updatedUser = await this.$api.users.update(userId, payload);

                    this.users.forEach(user => {
                        if (user.id != userId) {
                            return;
                        }

                        deepMerge(user, { objectives: updatedUser.objectives });
                    });
                } catch (error) {
                    this.$notify.error(this.$t('objectives.alerts.update.error'));
                }
            },
        },

        created() {
            this.updateObjective = debounce(this.updateObjective, 1000);

            useDashboardStore().dashboardType = DashboardType.ACTIVITY;
        },

        beforeDestroy() {
            this.$wait.end('fetching.objectives');
        },
    };
</script>
